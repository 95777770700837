@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Literata:ital,opsz@1,7..72&display=swap');

.TeamCard-body-text{
    background-color: #008AA4;
    margin-bottom: 40px;
    padding: 10px;

    min-height: 300px;

    display: flex;
    flex-direction: column;
    font-family: 'Merriweather Sans', sans-serif;
}

.TeamCard-nombre{
    font-size: 32px;
    text-align: center;
}

.TeamCard-puesto{
    color: whitesmoke;
    font-size: 20px;
    text-align: center;
}
.TeamCard-texto{
    font-size: 18px;
    font-style: italic;
}

