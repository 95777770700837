@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Literata:ital,opsz@1,7..72&family=Merriweather+Sans&display=swap');

.navbar-color{
    background-color: whitesmoke;
    -webkit-box-shadow: -4px 16px 12px 0px rgba(0,0,0,0.54);
    -moz-box-shadow: -4px 16px 12px 0px rgba(0,0,0,0.54);
    box-shadow: -4px 16px 12px 0px rgba(0,0,0,0.54);
}
.linea{
    background-color: #0096d3;
    height: 20px;
}
.logo{
    height: 50px;
    margin-bottom: 15px ;
}

.links{
    font-family: 'Merriweather Sans', sans-serif;
    color: #BC7D85;
    font-size: 20px;
    font-weight: 500;
}