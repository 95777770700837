@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Literata:ital,opsz@1,7..72&display=swap');

.abautus{
    min-height: 90vh;
    display: flex;
    align-items: center;
    background-image: url('../img/Fondo1.jpg');
    background-size: cover;
    
}

.abautus-text{
    Background-color: transparent;
    padding: 10px;
    
    text-align: center;
    -webkit-box-shadow: -1px 16px 21px 0px rgba(16,19,5,1);
    -moz-box-shadow: -1px 16px 21px 0px rgba(16,19,5,1);
    box-shadow: -1px 16px 21px 0px rgba(16,19,5,1);

    backdrop-filter: blur(10px);

    font-family: 'Cinzel Decorative', cursive !important;
}

.text{
    font-size: 4rem;
}

.geinsa{
    font-family: 'Literata', serif !important;
    font-style: italic;
    font-weight: 700;
    font-size: 2rem;
    opacity: 1;
}