@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Literata:ital,opsz@1,7..72&display=swap');

.objective-card{
    background-color: red;
}

.card-title-fake{
    font-size: 3rem;
    text-align: center;
    vertical-align: middle;
}

.card-text {
    text-align: justify;
    margin-bottom: 10px;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 2rem;
}