@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

.flip-card-outer {
  width: 100%;
  height: 70vh;
  margin: 25px 0;
  font-family: 'Merriweather Sans', sans-serif;
  
  &.focus-trigger:focus {
    outline: 5px solid greenyellow;
    outline-offset: 5px;
  }

  .flip-card-inner {
    transform-style: preserve-3d;
    transition: .5s linear .1s;
    position: relative;
    width: inherit;
    height: inherit;

    &.hover-trigger:hover {
      transform: rotateY(180deg);
    }

    &.showBack {
      transform: rotateY(180deg);
    }
  
    .card {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
  
      &.front {
        transform: rotateY(0);
        background-color: #008AA4;
        color: #fff;
      }
  
      &.back {
        transform: rotateY(180deg);
        background-color: #01528a;
        color: whitesmoke;
        border: transparent;
        
      }
    }
  }
}

.card-body{
  display: flex;
  flex-direction: column;
}

.id2 p{
  padding: 10px;
 font-size: 2vh; 
}