@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&family=Literata:ital,opsz@1,7..72&display=swap');


.footer{
    margin-top: 100px;
    padding: 30px;
    min-height: 100px;
    background-color: #008AA4;
    font-size: 18px;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: lighter;
}