.objectives{
    padding: 50px 0px;
}

.logo-container{
    padding: 100px;
    display: flex;

}

.objectives-col{
    margin-bottom: 10px;
}

.logo-objective{
    margin: auto;
    width: 70%;
 
}

.objectives-title{
    text-align: center;
    font-size: 3rem
}

.objectives-row{
    margin: 100px 0;
}

.valores-title-container{
    margin-bottom: 100px;
}

.valores-title{
    margin: auto;
    font-size: 100px;
    
}

.flip-vertical-fwd:hover {
	-webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-fwd {
    0% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
    100% {
      -webkit-transform: translateZ(160px) rotateY(180deg);
              transform: translateZ(160px) rotateY(180deg);
    }
  }
  @keyframes flip-vertical-fwd {
    0% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
    100% {
      -webkit-transform: translateZ(160px) rotateY(180deg);
              transform: translateZ(160px) rotateY(180deg);
    }
  }